import React from 'react'

import Stack from '@mui/material/Stack'

import { GA_ACTION, GA_EVENT, sendGAEvent } from '../analytics'
import { GRAPHQL_AUTH_CLIENT_ID, GRAPHQL_AUTH_DOMAIN } from '../constants'

import './Header.css'

import logo from '../images/goldstar-logo-white.png'

function Header(props) {

  return (
    <div className="header">
      <Stack direction="row" className="header-nav" alignItems="center" justifyContent="flex-start" spacing={2}>

        <div className="h-nav-goldstar">
          <div className="goldstar-icon"></div>
        </div>

        <div className="h-nav-primary">
          <div className="h-nav-a home"><a href="https://www.goldstar.com/">Home</a></div>
          <div className="h-nav-a"><a href="https://www.goldstar.com/los-angeles">Find Events</a></div>
        </div>
      </Stack>
    </div>
  )
}

export default Header
