export const GA_EVENT = {
  INTERACTION: 'interaction',
  VIEW: 'view',
  EVENT: 'event'
}

export const GA_ACTION = {
  INTERACTION: {
    DATE_PICKER: 'clicked get date picker',
    NUMBER_OF_TICKETS: 'clicked on number of tickets field',
    FIRST_NAME: 'clicked on first name field',
    LAST_NAME: 'clicked on last name field',
    EMAIL: 'clicked on email field',
    PHONE: 'clicked on phone field',
    SHOW_PREFERENCE: 'clicked on show preference',
    SHOW_TIME: 'clicked on show time',
    CAROUSEL: 'changed slides on carousel',

    CLICK_ARTS: 'selected arts category',
    CLICK_ATTRACTIONS: 'selected attractions category',
    CLICK_SPOOKY: 'selected spooky category',

    CLICK_EVENT: 'clicked on event to expand it',
    EXCLUDED_EVENT: 'clicked on event to exclude it',
    UNEXCLUDED_EVENT: 'clicked on event to unexclude it',

    DISCOUNT: 'clicked on discount field',

    CREDIT_CARD: 'clicked on credit card number field',
    EXPIRATION_MONTH: 'clicked on expiration month field',
    EXPIRATION_YEAR: 'clicked on expiration year field',
    CVC_CODE: 'clicked on cvc field',

    SUBMIT_PURCHASE: 'clicked to submit purchase',

    EXTRA_EXCLUSION: 'clicked to toggle extra exclusion',
    FAMILY_ROULETTE_EXCLUSION: 'clicked to toggle extra exclusion family roulette',
    NO_EXTRA_EXCLUSION: 'clicked no on extra exclusion',
    REMOVE_EXTRA_EXCLUSION: 'clicked x to remove extra exclusions',
    YES_EXTRA_EXCLUSION: 'clicked yes on extra exclusion',
    CLOSE_POPUP: 'clicked to close popup',

    PARKING_RESERVATION: 'clicked parking reservation add-on button',
    RESTAURANT_RESERVATION: 'clicked restaurant reservation add-on button',
    TICKETFLEX: 'clicked ticketflex add-on button',
    TICKETFLEX_YES_POPUP: 'clicked yes on ticketflex popup',

    GOOGLE_REVIEW_BUTTON: 'clicked to review us on google',
    YELP_REVIEW_BUTTON: 'clicked to review us on yelp',
  },
  VIEW: {
    EXCLUSION_POPUP: 'viewed extra exclusion popup',
    TICKETFLEX_POPUP: 'viewed ticketflex popup',
  },
  EVENT: {
    PAYMENT_FAILED: 'evented on failed payment after submitting purchase',
    PAYMENT_SUCCEEDED: 'evented on successful payment after submitting purchase',
    ORDER_INVALID: 'evented on invalid order after submitting purchase',
  }
}

export const sendGAEvent = (event, action, message) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    console.log(action)
    gtag('event', action)

  } else (
    console.log('would fire event: ' + event + ', action: ' + action)
  )
}

export const sendPurchaseGAEvent = (orderId, orderTotal, promoCode, itemName) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    console.log('purchase')

    gtag('event', 'purchase', {
      transaction_id: orderId,
      item_name: itemName,
      affiliation: 'Goldstar',
      value: orderTotal,
      currency: 'USD',
      coupon: promoCode
    })

  } else (
    console.log(`would fire event: purchased, ${orderId}, ${orderTotal}, ${promoCode}`)
  )
}