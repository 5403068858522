import React, { useCallback, useState, useEffect, lazy } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import BRClient from '../broadwayRouletteClient'
import {
  ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER,
  ARTS_ELIMINATIONS,
  ARTS_TIME,
  ATTRACTIONS_ELIMINATIONS,
  ATTRACTIONS_TIME,
  EVENT_CATEGORY_DETAILS,
  LOCALE,
  RATE_LIMIT_PERIOD_DEFAULT,
  EVENT_RATE_LIMIT_DEFAULT,
  PREMIUM_ELIMINATIONS,
  PREMIUM_TIME,
  STRIPE_ACCESS_KEY,
  TODAY,
  USD_DOLLARS_TO_CENTS
} from '../constants'

import { GA_ACTION, GA_EVENT, sendGAEvent, sendPurchaseGAEvent } from '../analytics'

import { getDateStr, getDateStrYYYYMMDD } from '../utils'
import { getDisabledDates } from '../frequencyCap'
import { isValidEventOrder } from '../orderValidation'

import {
  getPricingForEventOrder,
  HOLIDAY_DATES,
  PER_TICKET_EXTRA_ELIMINATIONS_FEE,
  PER_TICKET_TICKET_FLEX_FEE,
  PER_ORDER_RESTAURANT_FEE,
  PER_ORDER_PARKING_FEE
} from '../orderPricing'

import rouletteWheel from '../images/goldstar-roulette-wheel.webp'
import box from '../images/box.webp'

import desktopHeader from '../images/desktop-header.webp'
import tabletHeader from '../images/tablet-header.webp'
import mobileHeader from '../images/mobile-header.webp'


const AddOn = lazy(() => import ('./AddOn.js'))
const Checkout = lazy(() => import ('./Checkout.js'))
const DateAndTicketSelection = lazy(() => import ('./DateAndTicketSelection.js'))
const EventElimination = lazy(() => import ('./EventElimination.js'))

const MIN_DATE = moment(TODAY)

// Return tomorrow's date as a moment object
const getFutureAsDate = (daysAhead = 1) => {
  var dateTomorrow = moment().tz(LOCALE).add(daysAhead, 'days').toDate()
  var futureDate = MIN_DATE.toDate()

  return futureDate
}

function SpinPageLA(props) {
  // Create states
  const [alertStr, setAlertStr] = useState('')
  const [verificationStr, setVerificationStr] = useState('')

  const [isAlertPopupVisible, setIsAlertPopupVisible] = useState(false)
  const [isTicketFlexPopupVisible, setIsTicketFlexPopupVisible] = useState(false)
  const [isCategoryDisabledPopupVisible, setIsCategoryDisabledPopupVisible] = useState(false)

  const [hasLoadedUser, setHasLoadedUser] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const isMobile = windowWidth < 800

  const [user, setUser] = useState({
    email: '',
    eventOrders: {},
    firstName: '',
    lastName: '',
    isNewUser: true,
    lastName: '',
    mobilePhone: '',
    purchaseCredits: [],
  })

  var defaultRequestedDate = getFutureAsDate()
  var defaultEventPreference = isMobile ? 'none' : 'Arts'

  const [order, setOrder] = useState({
    location: 'Los Angeles',
    retailer: 'Goldstar',
    numberOfTickets: 2,
    requestedDate: defaultRequestedDate,
    requestedTime: 'Evening',
    rouletteType: 'Regular',
    isBundle: false,
    isRequestingAdditionalExclusions: false,
    isRequestingParkingReservation: false,
    isRequestingRestaurantReservation: false,
    isRequestingTicketFlex: false,
    eventPreference: defaultEventPreference,
    isOptingIntoMailingList: false,
    clientVersion: '1.0.0'
  })

  const [userEliminatedEvents, setUserEliminatedEvents] = useState({})

  const [frequencyCap, setFrequencyCap] = useState('')

  const [giftCardCode, setGiftCardCode] = useState('')
  const [purchaseCode, setPurchaseCode] = useState('')

  const [pricingDirty, setPricingDirty] = useState(true)
  const [serverPricing, setServerPricing] = useState(false)

  const [adminDisabledDates, setAdminDisabledDates] = useState([])
  const [isResolvingPurchase, setIsResolvingPurchase] = useState(false)

  const getIsUserAuthenticated = () => {
    return user && user.id
  }

  const isUserAuthenticated = getIsUserAuthenticated()

  // Set first available event date to tomorrow
  const autoAdvanceNextAvailableEventDate = () => {
    var nextAvailableEventDate = getFutureAsDate()
    var eventTimeForNextAvailableEventDate = getDefaultEventTime(nextAvailableEventDate)

    var newOrderState = {...order}

    newOrderState.requestedDate = nextAvailableEventDate
    newOrderState.requestedTime = eventTimeForNextAvailableEventDate

    setOrder(newOrderState)
  }

  const ensureValidDateTimeSelected = () => {
    var requestedDate = moment(order.requestedDate, 'MM-DD-YYYY')

    // Format disabled dates list
    var disabledDates = getAllDisabledDates()
    var disabledDatesSet = {}
    disabledDates.forEach(date => {
      disabledDatesSet[moment(date).format('YYYY-MM-DD')] = true
    })

    // Determine if input is a valid date
    var isInvalidDate = (date) => (
      moment(date).format('YYYY-MM-DD') in disabledDatesSet ||
      moment(date).format('YYYY-MM-DD') < MIN_DATE
    )

    if (!isInvalidDate(requestedDate)) {
      return
    } else {
      // Loop through requested dates until we find a valid date
      while (isInvalidDate(requestedDate)) {
        requestedDate = moment(requestedDate).add(1, 'days').toDate()
      }

      // Get default event time for found valid date
      var requestedTime = getDefaultEventTime(requestedDate)
      var newOrderState = {...order}

      newOrderState.requestedDate = requestedDate
      newOrderState.requestedTime = requestedTime

      setOrder(newOrderState)
    }
  }

  // Return default time based off day of week and event preference
  const getDefaultEventTime = (requestedDateMoment) => {
    var requestedDateString = moment(requestedDateMoment).format('YYYY-MM-DD')
    var disabledOptionsForDate = getDisabledOptionsForDate(requestedDateString)
    var requestedTime = 'Evening'

    var selectedDateEveningDisabledDates = disabledOptionsForDate.evening.filter(disabledDate => (
      disabledDate.rouletteType === 'All'
    ))

    var selectedDateAfternoonDisabledDates = disabledOptionsForDate.afternoon.filter(disabledDate => (
      disabledDate.rouletteType === 'All'
    ))

    var selectedDateAllDayDisabledDates = disabledOptionsForDate.allday.filter(disabledDate => (
      disabledDate.rouletteType === 'All'
    ))

    var isEveningEnabled = selectedDateEveningDisabledDates.length === 0
    var isAfternoonEnabled = selectedDateAfternoonDisabledDates.length === 0
    var isAllDayEnabled = selectedDateAllDayDisabledDates.length === 0

    if (isEveningEnabled && order.eventPreference === 'Arts') {
      requestedTime = 'Evening'
    } else if (isAfternoonEnabled && order.eventPreference === 'Attractions') {
      requestedTime = 'Afternoon'
    } else if (isAllDayEnabled) {
      requestedTime = 'AllDay'
    } else {
      // TODO: what if all times are cancelled and the disabled dates filter missed the date?
    }

    return requestedTime
  }

  const getAllDisabledDates = () => {
    // Get disabled dates set by admins
    var fullyDisabledDates = adminDisabledDates.filter(disabledDate => disabledDate.rouletteType === 'All' &&
                                                                       disabledDate.rouletteTime === 'All')
                                               .map(disabledDate => { return disabledDate.date })
    var disabledDates = [...fullyDisabledDates]

    // Get event rate limit and limit period
    var eventRateLimit = (frequencyCap && frequencyCap.eventRateLimit) || EVENT_RATE_LIMIT_DEFAULT
    var rateLimitPeriod = (frequencyCap && frequencyCap.rateLimitPeriod) || RATE_LIMIT_PERIOD_DEFAULT

    // See if user's previous order history exceeds limit, and if so, disable dates for user to enforce rate limit
    var orderDates = user.eventOrders.length > 0 && user.eventOrders
                     .filter(order => order.isValidOrder && order.location.name === 'Los Angeles' && order.retailer.name === 'Goldstar')
                     .map(order => getDateStr(order.requestedDate))
    var frequencyCappedDisabledDates = getDisabledDates(orderDates, eventRateLimit, rateLimitPeriod)

    return [...disabledDates, ...frequencyCappedDisabledDates]
  }

  // Retrieve disabled options set by admins
  const getDisabledOptionsForDate = (requestedDateString) => {
    // Get all valid disabled dates for selected show date
    var selectedDateDisabledDates = adminDisabledDates.filter(disabledDate => (
      (disabledDate.date === requestedDateString && disabledDate.isActive)
    ))

    // Get all valid disabled dates for selected show date by time
    var eveningDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      (disabledDate.rouletteTime === 'Evening' || disabledDate.rouletteTime === 'All')
    ))

    var afternoonDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      (disabledDate.rouletteTime === 'Afternoon' || disabledDate.rouletteTime === 'All')
    ))

    var allDayDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      (disabledDate.rouletteTime === 'All-Day' || disabledDate.rouletteTime === 'All')
    ))

    return ({ evening: eveningDisabledDates, afternoon: afternoonDisabledDates, allday: allDayDisabledDates })
  }

  // Filter and format disabled dates
  const getFilteredDisabledDates = (adminDisabledDatesQuery, category, key) => {
    var adminDisabledFilteredDates = adminDisabledDatesQuery.data.disabledEventDatesList.items
                                     .filter(disabledDate => disabledDate[category] === key)
                                     .map(disabledDate => {
                                       return moment(disabledDate.date).tz(LOCALE).toDate()
                                     })

    return adminDisabledFilteredDates
  }

  // Get cap for latest date a user can place an order
  const getMaxDate = () => {
    return moment('2022-11-30')
  }

  // Format and return all order data
  const getOrder = (serverFriendly = false) => {
    var requestedDate = getDateStr(order.requestedDate)
    if (serverFriendly) {
      // Client needs date formatted MM-DD-YYYY, server needs date formatted YYYY-MM-DD
      requestedDate = getDateStrYYYYMMDD(order.requestedDate)
    }

    return {
      email: user.email,
      excludedEvents: Object.keys(userEliminatedEvents),
      location: order.location,
      retailer: order.retailer,
      numberOfTickets: order.numberOfTickets,
      requestedDate: requestedDate,
      requestedTime: order.requestedTime,
      rouletteType: order.rouletteType,
      isBundle: order.isBundle,
      isNewUser: user.isNewUser,
      isRequestingAdditionalExclusions: !!order.isRequestingAdditionalExclusions,
      isRequestingParkingReservation: !!order.isRequestingParkingReservation,
      isRequestingRestaurantReservation: !!order.isRequestingRestaurantReservation,
      isRequestingTicketFlex: order.isRequestingTicketFlex,
      eventPreference: order.eventPreference,
      isOptingIntoMailingList: false,
      clientVersion: '1.0.0'
    }
  }

  // Retrieve and format a users previously attended events
  const getPreviouslyAttendedEvents = () => {
    if (!isUserAuthenticated || !user.eventOrders || !(user.eventOrders instanceof Array)) { return [] }

    var previouslySeenEvents = user.eventOrders.filter(order => {
      return order.isValidOrder && order.requestedDate < moment().format('YYYY-MM-DD') &&
             order.location.name === 'Los Angeles' && order.retailer.name === 'Goldstar'
    })
    .map(order => order.ticketDetails.event)
    .filter(event => event && event.id)

    var previouslySeenEventIds = {}
    previouslySeenEvents.forEach(event => { previouslySeenEventIds[event.id] = true })

    return previouslySeenEventIds
  }

  const handleConfirmPayment = async (paymentResult, orderId) => {
    return new Promise(async (resolve, reject) => {
      try {
        var charge = getCharge(user, order)

        var response = await BRClient.confirmStripePayment(paymentResult, charge, 'usd', orderId)
        var responseJson = await response.json()

        resolve(responseJson)

      } catch (e) {
        console.log(e)
        handlePaymentFailure({
          message: `We're sorry! We weren't able to place your order just now. Please try again in a little while.`
        })
      }
    })
  }

  const handlePaymentFailure = ({ message }) => {
    setAlertStr(message)
    setIsResolvingPurchase(false)
    setIsAlertPopupVisible(true)

    console.log(message)

    sendGAEvent(GA_EVENT.EVENT, GA_ACTION.EVENT.PAYMENT_FAILED)
  }

  const handlePaymentSuccess = (orderId) => {
    var charge = getCharge(user, order)
    var orderDetails = getOrder()
    var pricing = getPricingForEventOrder(orderDetails, user, serverPricing)

    var numberOfTickets = order.numberOfTickets
    var requestedShowDate = order.requestedShowDate
    var totalCost = charge.amount / USD_DOLLARS_TO_CENTS
    var itemName = 'Roulette LA tickets'

    sendPurchaseGAEvent(orderId, totalCost, purchaseCode, itemName)
    props.history.push(`/confirmation/${orderId}?location=${order.location}`)
  }

  const getCharge = (user, order) => {
    var userEmail = user.email
    var orderDetails = getOrder()
    var pricingInfo = getPricingForEventOrder(orderDetails, user, serverPricing)

    return {
      description: userEmail,
      zipCode: true,
      amount: pricingInfo.totalCost * USD_DOLLARS_TO_CENTS
    }
  }

  const handleCreateOrder = async (paymentResult, paymentSource, paymentMethod) => {
    return new Promise(async (resolve, reject) => {
      setIsResolvingPurchase(true)
      var userOrderCount = user.orders && user.orders.length > 0
                           ? user.orders.filter(order => order.isValidOrder).length
                           : 0
      user.orders = userOrderCount
      var charge = getCharge(user, order)

      // Create an order object consumable by the BE
      var formattedOrder = getOrder(true)

      if (paymentMethod && paymentMethod.billing_details) {
        user.zipCode = paymentMethod.billing_details.address.postal_code
      }

      try {
        var response = await BRClient.createEventOrder(charge, user, formattedOrder, purchaseCode, giftCardCode, paymentSource, paymentResult)
        var responseJson = await response.json()

        resolve(responseJson)

      } catch (e) {
        console.log(e)
        handlePaymentFailure({
          message: `We're sorry! We weren't able to place your order just now. Please try again in a little while.`
        })
      }
    })
  }

  const handleUpdateOrder = (key, value) => {
    var newOrderState = {...order}

    newOrderState[key] = value
    setOrder(newOrderState)
  }

  const handleUpdateUser = (key, value) => {
    var newUserState = {...user}
    var formattedValue = key === 'mobilePhone' ? getInternationalPhoneNumber(value) || value : value

    newUserState[key] = formattedValue
    setUser(newUserState)

    if (key === 'email') {
      lazySearchUserEmail()
      resetPurchaseCode()
    }
  }

  const handleChangeEventPreference = (togglePreference) => {
    handleUpdateOrder('eventPreference', togglePreference)

    setUserEliminatedEvents([])
    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.EVENT_PREFERENCE)
  }

  const handleAcceptTicketflex = () => {
    setIsTicketFlexPopupVisible(false)
    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', true)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.TICKETFLEX_YES_POPUP)
  }

  const handleRejectTicketflex = () => {
    setIsTicketFlexPopupVisible(false)
    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', false)
  }

  const handleToggleAdditionalExclusions = () => {
    var newAdditionalExclusions = !order.isRequestingAdditionalExclusions

    setPricingDirty(true)
    handleUpdateOrder('isRequestingAdditionalExclusions', newAdditionalExclusions)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.EXTRA_EXCLUSION)
  }

  const handleToggleParkingReservation = () => {
    var newParkingReservation = !order.isRequestingParkingReservation

    setPricingDirty(true)
    handleUpdateOrder('isRequestingParkingReservation', newParkingReservation)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.PARKING_RESERVATION)
  }

  const handleToggleRestaurantReservation = () => {
    var newRestaurantReservation = !order.isRequestingRestaurantReservation

    setPricingDirty(true)
    handleUpdateOrder('isRequestingRestaurantReservation', newRestaurantReservation)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.RESTAURANT_RESERVATION)
  }

  const handleToggleTicketFlex = () => {
    var newTicketFlex = !order.isRequestingTicketFlex

    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', newTicketFlex)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.TICKETFLEX)
  }

  const handleToggleTicketFlexDialog = () => {
    setIsTicketFlexPopupVisible(false)

    sendGAEvent(GA_EVENT.VIEW, GA_ACTION.VIEW.TICKETFLEX_POPUP)
  }

  const loadAdminDisabledDates = async () => {
    try {
      if (!props.adminDisabledDatesQuery.loading && !props.adminDisabledDatesQuery.error) {
        var adminDisabledDatesQuery = props.adminDisabledDatesQuery
        setAdminDisabledDates(adminDisabledDatesQuery.disabledEventDatesList.items)
      }

    } catch (err) {
      console.log(`Unable to load excluded dates: ${err.message}`)
      console.log(err)
    }
  }

  const loadConfig = async () => {
    try {
      var configQuery = await props.client.query({
        query: CONFIG_JSON_QUERY,
        variables: { key: 'frequencyCap' }
      })

      setFrequencyCap(configQuery.data.configJson.value)

    } catch (err) {
      console.log(`Unable to load config: ${err.message}`)
    }
  }

  const loadUser = async () => {
    try {
      var userQuery = await BRClient.getUserDetails(email)
      var userDetails = await userQuery.json()

      if (!userDetails) {
        setHasLoadedUser(true)
        return
      }

      var email = userDetails.email
      var firstName = userDetails.firstName
      var isNewUser = false
      var lastName = userDetails.lastName
      var mobilePhone = userDetails.mobilePhone

      var eventOrders = userDetails.eventOrders.items && userDetails.eventOrders.items.map(order => {
                          if (order.isValidOrder && order.location.name === 'Los Angeles' && order.retailer.name === 'Goldstar') {
                            return order
                          }
                        })

      var capDate = moment().subtract(3, 'year').format("YYYY-MM-DD")
      var previousSpinsCount = eventOrders ? eventOrders.filter(order => ( order.orderPlacedAt > capDate )).length : 0

      var previouslySeenEvents = eventOrders ? eventOrders.filter(order => (
                                   order.orderPlacedAt < TODAY && order.ticketDetails && order.ticketDetails.event
                                 )) : []

      var purchaseCredits = userDetails.purchaseCredits ? userDetails.purchaseCredits.items.filter(credit => (
                              !credit.isExpired && credit.currency === 'USD' && credit.remainingAmount > 0
                            )) : []

      var userEliminatedEvents = {}

      setUser({
        id: userDetails.id,
        email,
        firstName,
        isNewUser,
        lastName,
        mobilePhone,
        eventOrders,
        previousSpinsCount,
        purchaseCredits,
        userEliminatedEvents
      })

      setHasLoadedUser(true)
    } catch (err) {
      console.log(`Unable to get data for user: ${err.message}`)
      setHasLoadedUser(true)
    }
  }

  const resetPurchaseCode = () => {
    setPurchaseCode('')
    setPricingDirty(true)
  }

  useEffect(() => {
    loadConfig()
    autoAdvanceNextAvailableEventDate()
  }, [])

  useEffect(() => {
    loadAdminDisabledDates()
  }, [props.adminDisabledDatesQuery])

  useEffect(async () => {
    try {
      var response = await BRClient.getUserDetailsFromGoldstar()
      var responseJson = await response.json()
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    ensureValidDateTimeSelected()
  }, [disabledDates, order, user])

  useEffect(() => {
    // if event preference is changed, clear restaurant reservation add-on and update pricing
    handleUpdateOrder('isRequestingRestaurantReservation', false)
    setPricingDirty(true)
  }, [order.eventPreference])

  useEffect(() => {
    if (!hasLoadedUser) {
      loadUser()
    }
  }, [hasLoadedUser, user])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  var maxDate = getMaxDate()
  var requestedDateString = moment(order.requestedDate).format('YYYY-MM-DD')
  var dayOfWeekRequested = moment(order.requestedDate, 'MM-DD-YYYY').format('dddd')

  var disabledDates = getAllDisabledDates()

  // Load disabled dates for selected date
  var disabledOptionsForDate = getDisabledOptionsForDate(requestedDateString)
  // Narrow down disabled dates to selected time
  var disabledOptionsForTime = disabledOptionsForDate[order.requestedTime.toLowerCase()]

  // Check if any times for selected date are entirely disabled for all spin types
  var selectedDateEveningDisabledDates = disabledOptionsForDate.evening.filter(disabledDate => (
    disabledDate.rouletteType === 'All'
  ))

  var selectedDateAfternoonDisabledDates = disabledOptionsForDate.afternoon.filter(disabledDate => (
    disabledDate.rouletteType === 'All'
  ))

  var selectedDateAllDayDisabledDates = disabledOptionsForDate.allday.filter(disabledDate => (
    disabledDate.rouletteType === 'All'
  ))

  return (
    <div className="spin static-page">
      <Helmet>
        <title>Goldstar Roulette</title>
        <meta name="description" content="Play Goldstar Roulette to attend an LA event on a budget" />
      </Helmet>

      <Stack className="page-width-stack">
        <div className="header-section">
          {windowWidth > 950 &&
            <img src={desktopHeader} className="header-image" width="1800px" />
          }

          {windowWidth <= 950 && windowWidth > 420 &&
            <img src={tabletHeader} className="header-image" width="1000px" />
          }

          {windowWidth <= 420 &&
            <img src={mobileHeader} className="header-image" width="500px" />
          }
        </div>

        <hr className="divider" />

        <DateAndTicketSelection disabledDates={disabledDates}
                                getDefaultEventTime={getDefaultEventTime}
                                handleUpdateOrder={handleUpdateOrder}
                                location="Los Angeles"
                                maxDate={maxDate}
                                minDate={MIN_DATE}
                                order={order}
                                resetPurchaseCode={resetPurchaseCode}
                                setOrder={setOrder}
                                setPricingDirty={setPricingDirty}
                                user={user}
                                windowWidth={windowWidth} />
      </Stack>

      <EventElimination disabledOptionsForDate={disabledOptionsForDate}
                        getPreviouslyAttendedEvents={getPreviouslyAttendedEvents}
                        handleUpdateOrder={handleUpdateOrder}
                        order={order}
                        setOrder={setOrder}
                        setPricingDirty={setPricingDirty}
                        setUserEliminatedEvents={setUserEliminatedEvents}
                        userEliminatedEvents={userEliminatedEvents}
                        windowWidth={windowWidth} />

      <Stack className="page-width-stack">
        <div className="add-ons-section">
          <Stack className="add-ons-stack">

            <AddOn booleanCheck={order.isRequestingTicketFlex}
                   cost={`${PER_TICKET_TICKET_FLEX_FEE}/Ticket`}
                   description={'Things happen! TicketFlex gives you the flexibility to change your date up to 7 days before the event.'}
                   handleToggle={handleToggleTicketFlex}
                   title={'TicketFlex'}
                   windowWidth={windowWidth} />

            {order.eventPreference === 'Arts' &&
              <AddOn booleanCheck={order.isRequestingRestaurantReservation}
                     cost={`${PER_ORDER_RESTAURANT_FEE}`}
                     description={'Make the entire outing a surprise! We’ll make a reservation for you based on your event location and number of tickets.'}
                     handleToggle={handleToggleRestaurantReservation}
                     title={'Restaurant Reservation'}
                     windowWidth={windowWidth} />
            }
          </Stack>
        </div>
      </Stack>

      <Stack className="page-width-stack">
        <Stack className="how-step-stack">
          <Stack direction="row" alignItems="center">
            <img src={box} width="100px" height="auto" className="how-image" />
          </Stack>

          <Stack direction="column">
            <div className="how-title">Checkout to be surprised with an amazing event</div>
            <div className="how-desc">2 days before your selected date, find out which event you won!</div>
          </Stack>
        </Stack>
      </Stack>

      <Checkout alertStr={alertStr}
                getAllDisabledDates={getAllDisabledDates}
                getCharge={getCharge}
                getOrder={getOrder}
                getPricingForOrder={getPricingForEventOrder}
                giftCardCode={giftCardCode}
                handleConfirmPayment={handleConfirmPayment}
                handleCreateOrder={handleCreateOrder}
                handlePaymentFailure={handlePaymentFailure}
                handlePaymentSuccess={handlePaymentSuccess}
                handleUpdateOrder={handleUpdateOrder}
                isAlertPopupVisible={isAlertPopupVisible}
                isResolvingPurchase={isResolvingPurchase}
                eventLocation="Los Angeles"
                order={order}
                pricingDirty={pricingDirty}
                purchaseCode={purchaseCode}
                resetPurchaseCode={resetPurchaseCode}
                setAlertStr={setAlertStr}
                setGiftCardCode={setGiftCardCode}
                setIsAlertPopupVisible={setIsAlertPopupVisible}
                setPricingDirty={setPricingDirty}
                setPurchaseCode={setPurchaseCode}
                setServerPricing={setServerPricing}
                setUser={setUser}
                serverPricing={serverPricing}
                user={user}
                userEliminations={userEliminatedEvents} />

      <div className="footer">
        Use of this site is governed by our <a href="https://www.goldstar.com/company/tos">Terms of Service</a>&nbsp;
        and <a href="https://www.goldstar.com/company/privacy">Privacy Policy</a> ©2022 Goldstar Events, Inc.
      </div>

      <Dialog className="popup-dialog"
              onClose={() => {
                setAlertStr: ''
                setIsAlertPopupVisible(false)
              }}
              open={(!!alertStr || !!verificationStr) && isAlertPopupVisible} >
        <div className="popup-content">
          <h2>{alertStr || verificationStr}</h2>
          <div className="popup-buttons">
            <span className="cta-button ok-button"
                  onClick={() => {
                    setAlertStr('')
                    setIsAlertPopupVisible(false)
                  }}>
              Ok!
            </span>
          </div>
          <span className="dialog-close header-description"
                onClick={() => {
                  setAlertStr('')
                  setIsAlertPopupVisible(false)
                }}>
            <CloseIcon />
          </span>
        </div>
      </Dialog>

      <Dialog className="popup-dialog"
              onClose={() => { setIsCategoryDisabledPopupVisible(false) }}
              open={isCategoryDisabledPopupVisible} >
        <div className="popup-content">
          <h2>
            Sorry, this spin category is not available for your selected date and time.
          </h2>
          <span className="dialog-close header-description"
                onClick={() => { setIsCategoryDisabledPopupVisible(false) }}>
            <CloseIcon />
          </span>
        </div>
      </Dialog>
    </div>
  )
}

const DISABLED_EVENT_DATES_LIST_QUERY = gql`
  query DisabledEventDatesListQuery($today: Date!) {
    disabledEventDatesList(
      sort: { date: DESC },
      filter: {
        date: { gte: $today },
        isActive: { equals: true },
        location: { name: { equals: "Los Angeles" }}
        retailer: { name: { equals: "Goldstar" }}
      }
    ){
      items {
        id
        date
        isActive
        rouletteType
        rouletteTime
      }
    }
  }
`

const CONFIG_JSON_QUERY = gql`
  query ConfigQuery($key: String!) {
    configJson(key: $key) {
      id
      key
      value
    }
  }
`

const PURCHASE_CODE_QUERY = gql`
  query PurchaseCodeQuery($code: String) {
    purchaseCode(code: $code) {
      id
      code
      isOneTimeUsePerUser
      purchaseCodeType
    }
  }
`

export default withRouter(withApollo(graphql(DISABLED_EVENT_DATES_LIST_QUERY, {
  name: 'adminDisabledDatesQuery',
  options: (ownProps) => {
    var today = moment().format('YYYY-MM-DD')

    return {
      notifyOnNetworkStatusChange: true,
      variables: {
        today
      }
    }
  }
})(SpinPageLA)))