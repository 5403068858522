import moment from 'moment-timezone'
import { addDaysToDateStrMMDDYYYYUsingMoment, addDaysToDateStrYYYYMMDDUsingMoment  } from './utils'

export const getDisabledDates = (dates, rateLimit, rateLimitPeriod) => {

  var events = []
  dates && dates.forEach((orderDate) => {
    var rangeStart = addDaysToDateStrMMDDYYYYUsingMoment(orderDate, -1 * (rateLimitPeriod - 1))
    var rangeEnd = addDaysToDateStrMMDDYYYYUsingMoment(orderDate, 0)

    events.push({
      date: rangeStart,
      type: 'start'
    })

    events.push({
      date: rangeEnd,
      type: 'end'
    })
  })

  events = events.sort((a,b) => {
    if (a.date < b.date) { return -1 }
    else if (a.date === b.date) { return 0 }
    else { return 1 }
  })

  var count = 0
  events.forEach((evt) => {
    if (evt.type === 'start') {
      count++
      evt.value = count
    } else {
      evt.value = count
      count--
    }
  })

  events = events.filter(evt => rateLimit <= evt.value)

  var ranges = []
  for (var i = 0; i < events.length; i += 2) {
    ranges.push({ start: events[i].date, end: events[i+1].date })
  }

  ranges = ranges.map(range => {
    var start = addDaysToDateStrYYYYMMDDUsingMoment(range.start, 0)
    var end = addDaysToDateStrYYYYMMDDUsingMoment(range.end, (rateLimitPeriod - 1))

    return { start, end }
  })

  ranges = ranges.reduce((acc, cur) => {
    var lastRange = acc[acc.length-1]
    var hasOverlap = lastRange && cur.start <= lastRange.end

    if (hasOverlap) {
      lastRange.end = cur.end
    } else {
      acc.push(cur)
    }

    return acc
  }, [])

  var disabledDates = []
  ranges.forEach(range => {
    var date = range.start
    while (date <= range.end) {
      disabledDates.push(date)
      date = addDaysToDateStrYYYYMMDDUsingMoment(date, 1)
    }
  })

  return disabledDates.map(date => moment(date).format('YYYY-MM-DD'))
}
