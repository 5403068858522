import { EVENT_COST_PER_DAY, BR_COST_PER_DAY } from './orderPricing'

const moment = require('moment-timezone')

export const ENV = process.env.REACT_APP_STAGE

// Clients
const ENV_TO_BR_API = {
  'DEV': 'http://localhost:4000',
  'PROD': 'https://roulette-api.goldstar.com',
}

const ENV_TO_PAYPAL_ACCESS_KEY = {
  'DEV': process.env.REACT_APP_PAYPAL_DEV_KEY,
  'PROD': process.env.REACT_APP_PAYPAL_LIVE_KEY,
}

const ENV_TO_STRIPE_ACCESS_KEY = {
  'DEV': process.env.REACT_APP_STRIPE_DEV_KEY,
  'PROD': process.env.REACT_APP_STRIPE_LIVE_KEY,
}

export const MailChimp = {
  OptInUrl: {
    MonthlySpin: 'https://broadwayroulette.us12.list-manage.com/subscribe?u=3c4c9afdbb28160af716b104d&id=d095aca221',
    AllCustomers: 'http://broadwayroulette.us12.list-manage.com/subscribe?u=3c4c9afdbb28160af716b104d&id=2b10bec198',
  },
}

export const PAYPAL_ACCESS_KEY = ENV_TO_PAYPAL_ACCESS_KEY[ENV]
export const STRIPE_ACCESS_KEY = ENV_TO_STRIPE_ACCESS_KEY[ENV]
export const BR_API_URL = ENV_TO_BR_API[ENV]
export const BR_API_KEY = process.env.REACT_APP_BR_API_KEY

export const GC_USER_ID = 'graphcool-user-id'
export const GC_AUTH_TOKEN = 'graphcool-auth-token'
export const GRAPHQL_API_URL = 'https://api.8base.com/ck5o3an76000508ladchh9i4h'
export const GRAPHQL_AUTH_CLIENT_ID = process.env.REACT_APP_GRAPHQL_AUTH_CLIENT_ID
export const GRAPHQL_AUTH_DOMAIN = 'auth.broadwayroulette.com'
export const GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE = 'br-auth-token'
export const GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION = 'br-auth-token-expiration'

export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY

// Business Logic
export const EVENT_RATE_LIMIT_DEFAULT = 4
export const RATE_LIMIT_PERIOD_DEFAULT = 28

export const ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER_NY = 120

export const MAX_ELIMINATION_COUNT = 4
export const UPCHARGE_ADDITIONAL_EXCLUSIONS = 2
export const UPCHARGE_ADDITIONAL_EVENT_EXCLUSIONS = 1

export const MIN_TICKET_REQUEST = 1
export const MAX_TICKET_REQUEST = 20

export const EXCLUDING_TOO_MUCH_COPY = 'Note: Because there are 3 or fewer shows that match your preferred show type (musical or play), we may include shows of the other type in your spin.'

// Roulette Times
export const ARTS_TIME = 'Evening'
export const ATTRACTIONS_TIME = 'Afternoon'
export const PREMIUM_TIME = 'AllDay'

// Roulette Eliminations
export const ARTS_ELIMINATIONS = 3
export const ATTRACTIONS_ELIMINATIONS = 3
export const PREMIUM_ELIMINATIONS = 2

// General Logic
export const USD_DOLLARS_TO_CENTS = 100
export const LOCALE = 'America/Los_Angeles'
export const LOCALE_NY = 'America/New_York'
export const TODAY = moment().tz(LOCALE).format('YYYY-MM-DD')
export const TODAY_DATE_TIME = moment().tz(LOCALE).format('YYYY-MM-DD HH:mm A')
export const MATINEE_CUTOFF = '16:00:00'

// Event Roulette Copy
export const EVENT_CATEGORY_DETAILS = {
  'Los Angeles': {
    'Arts': {
      title: 'Theatre & Arts',
      tagline: 'Experience the arts',
      copy: 'Dive into LA’s performing arts scene from $45',
      timeCopy: '6pm and 9pm',
      time: ARTS_TIME,
      eliminations: ARTS_ELIMINATIONS,
      price: EVENT_COST_PER_DAY['Arts']
    },

    'Attractions': {
      title: 'Museums & Attractions',
      tagline: 'See the sights',
      copy: 'Get to know LA from $25',
      timeCopy: '12pm and 3pm',
      time: ATTRACTIONS_TIME,
      eliminations: ATTRACTIONS_ELIMINATIONS,
      price: EVENT_COST_PER_DAY['Attractions']
    },

    'Premium': {
      title: 'Halloween',
      tagline: 'Iconic LA',
      copy: 'Have an unforgettable LA experience from $65',
      timeCopy: '12pm and 9pm',
      time: PREMIUM_TIME,
      eliminations: PREMIUM_ELIMINATIONS,
      price: EVENT_COST_PER_DAY['Premium']
    },
  },

  'Broadway': {
    'Regular': {
      title: 'Standard',
      timeCopy: 'See a Broadway show for just ',
      price: BR_COST_PER_DAY
    },

    'Family-Friendly': {
      title: 'Family-Friendly',
      timeCopy: 'Take kids age 10+ to a Broadway show for just ',
      price: BR_COST_PER_DAY
    },

    'Premium': {
      title: 'Premium',
      timeCopy: 'Guarantee Broadway orchestra seats for just ',
      price: BR_COST_PER_DAY
    },
  }
}

export const GOLDSTAR_DEFAULT_IMAGE = "https://goldstarroulette-media.s3.us-east-1.amazonaws.com/la/images/default.webp"