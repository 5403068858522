import React, { useCallback, useState, useEffect, lazy } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import BRClient from '../broadwayRouletteClient'
import {
  ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER_NY,
  LOCALE_NY,
  RATE_LIMIT_PERIOD_DEFAULT,
  EVENT_RATE_LIMIT_DEFAULT,
  STRIPE_ACCESS_KEY,
  TODAY,
  USD_DOLLARS_TO_CENTS
} from '../constants'

import { GA_ACTION, GA_EVENT, sendGAEvent, sendPurchaseGAEvent } from '../analytics'

import { getDateStr, getDateStrYYYYMMDD } from '../utils'
import { getDisabledDates } from '../frequencyCap'

import {
  getPricingForOrder,
  HOLIDAY_DATES,
  PER_TICKET_EXTRA_ELIMINATIONS_FEE,
  PER_TICKET_TICKET_FLEX_FEE,
  PER_ORDER_RESTAURANT_FEE,
  PER_ORDER_PARKING_FEE
} from '../orderPricing'

import rouletteWheel from '../images/goldstar-roulette-wheel.webp'
import box from '../images/box.webp'
import forbes from '../images/forbes_grey.webp'
import hollywoodReporter from '../images/hollywood_grey.webp'
import pureWow from '../images/purewow_grey.webp'

import desktopHeader from '../images/desktop-header-ny.webp'
import tabletHeader from '../images/tablet-header-ny.webp'
import mobileHeader from '../images/mobile-header-ny.webp'


const AddOn = lazy(() => import ('./AddOn.js'))
const Checkout = lazy(() => import ('./Checkout.js'))
const DateAndTicketSelection = lazy(() => import ('./DateAndTicketSelection.js'))
const ShowElimination = lazy(() => import ('./ShowElimination.js'))

const MIN_DATE = moment(TODAY).add(1, 'days')

// Return tomorrow's date as a moment object
const getFutureAsDate = (daysAhead = 1) => {
  var dateTomorrow = moment().tz(LOCALE_NY).add(daysAhead, 'days').toDate()
  var futureDate = TODAY >= moment('2022-08-01') ? dateTomorrow : MIN_DATE.toDate()

  return futureDate
}

function SpinPageNY(props) {
  // Create states
  const [alertStr, setAlertStr] = useState('')

  const [isTicketFlexPopupVisible, setIsTicketFlexPopupVisible] = useState(false)
  const [isCategoryDisabledPopupVisible, setIsCategoryDisabledPopupVisible] = useState(false)
  const [isAlertPopupVisible, setIsAlertPopupVisible] = useState(false)

  const [hasLoadedUser, setHasLoadedUser] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const [pricingDirty, setPricingDirty] = useState(true)
  const [serverPricing, setServerPricing] = useState(false)

  const [isResolvingPurchase, setIsResolvingPurchase] = useState(false)

  const isMobile = windowWidth < 800

  const [user, setUser] = useState({
    email: '',
    orders: {},
    firstName: '',
    lastName: '',
    isNewUser: true,
    lastName: '',
    mobilePhone: '',
    purchaseCredits: [],
  })

  var defaultRequestedDate = getFutureAsDate()
  var defaultEventPreference = isMobile ? 'none' : 'Regular'

  const [order, setOrder] = useState({
    location: 'Broadway',
    retailer: 'Goldstar',
    numberOfTickets: 2,
    requestedDate: defaultRequestedDate,
    requestedTime: 'Evening',
    rouletteType: 'Regular',
    isBundle: false,
    isRequestingAdditionalExclusions: false,
    isRequestingParkingReservation: false,
    isRequestingRestaurantReservation: false,
    isRequestingTicketFlex: false,
    eventPreference: defaultEventPreference,
    isOptingIntoMailingList: false,
    clientVersion: '1.0.0'
  })

  const [userEliminatedShows, setUserEliminatedShows] = useState({})

  const [frequencyCap, setFrequencyCap] = useState('')

  const [giftCardCode, setGiftCardCode] = useState('')
  const [purchaseCode, setPurchaseCode] = useState('')

  const [adminDisabledDates, setAdminDisabledDates] = useState([])

  const getIsUserAuthenticated = () => {
    return user && user.id
  }

  const isUserAuthenticated = getIsUserAuthenticated()

  // Set first available show date to tomorrow
  const autoAdvanceNextAvailableShowDate = () => {
    var nextAvailableShowDate = getFutureAsDate()
    var showTimeForNextAvailableShowDate = getDefaultEventTime(nextAvailableShowDate)

    var newOrderState = {...order}

    newOrderState.requestedDate = nextAvailableShowDate
    newOrderState.requestedTime = showTimeForNextAvailableShowDate

    setOrder(newOrderState)
  }

  const ensureValidDateTimeSelected = () => {
    var requestedDate = moment(order.requestedDate, 'MM-DD-YYYY')

    // Format disabled dates list
    var disabledDates = getAllDisabledDates()
    var disabledDatesSet = {}
    disabledDates.forEach(date => {
      disabledDatesSet[moment(date).format('YYYY-MM-DD')] = true
    })

    // Determine if input is a valid date
    var isInvalidDate = (date) => (
      moment(date).format('YYYY-MM-DD') in disabledDatesSet ||
      moment(date).format('dddd') === 'Monday' ||
      moment(date).format('YYYY-MM-DD') < MIN_DATE
    )

    if (!isInvalidDate(requestedDate)) {
      return
    } else {
      // Loop through requested dates until we find a valid date
      while (isInvalidDate(requestedDate)) {
        requestedDate = moment(requestedDate).add(1, 'days').toDate()
      }

      // Get default show time for found valid date
      var requestedTime = getDefaultEventTime(requestedDate)
      var newOrderState = {...order}

      newOrderState.requestedDate = requestedDate
      newOrderState.requestedTime = requestedTime

      setOrder(newOrderState)
    }
  }

  // Return default time based off day of week and show preference
  const getDefaultEventTime = (requestedDateMoment) => {
    var dayOfWeekRequested = moment(requestedDateMoment).format('dddd')
    var requestedTime = dayOfWeekRequested === 'Sunday' ? 'Matinee' : 'Evening'

    return requestedTime
  }

  const getAllDisabledDates = () => {
    // Get disabled dates set by admins
    var fullyDisabledDates = adminDisabledDates.filter(disabledDate => disabledDate.isForAllShows)
                                               .map(disabledDate => { return disabledDate.date })
    var disabledDates = [...fullyDisabledDates]

    // Get show rate limit and limit period
    var showRateLimit = (frequencyCap && frequencyCap.showRateLimit) || EVENT_RATE_LIMIT_DEFAULT
    var rateLimitPeriod = (frequencyCap && frequencyCap.rateLimitPeriod) || RATE_LIMIT_PERIOD_DEFAULT

    // See if user's previous order history exceeds limit, and if so, disable dates for user to enforce rate limit
    var orderDates = user.orders.length > 0 && user.orders
                     .filter(order => order.isValidOrder && order.location.name === 'Broadway' && order.retailer.name === 'Goldstar')
                     .map(order => getDateStr(order.requestedDate))
    var frequencyCappedDisabledDates = getDisabledDates(orderDates, showRateLimit, rateLimitPeriod)

    return [...disabledDates, ...frequencyCappedDisabledDates]
  }

  // Retrieve disabled options set by admins
  const getDisabledOptionsForDate = (requestedDateString) => {
    // Get all valid disabled dates for selected show date
    var selectedDateDisabledDates = adminDisabledDates.filter(disabledDate => (
      (disabledDate.date === requestedDateString && disabledDate.isActive)
    ))

    // Get all valid disabled dates for selected show date by time
    var eveningDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      disabledDate.isForEvening || disabledDate.isForAllShows
    ))

    var matineeDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      disabledDate.isForMatinee || disabledDate.isForAllShows
    ))

    return ({ evening: eveningDisabledDates, matinee: matineeDisabledDates })
  }

  // Filter and format disabled dates
  const getFilteredDisabledDates = (adminDisabledDatesQuery, category, key) => {
    var adminDisabledFilteredDates = adminDisabledDatesQuery.data.disabledDatesList.items
                                     .filter(disabledDate => disabledDate[category] === key)
                                     .map(disabledDate => {
                                       return moment(disabledDate.date).tz(LOCALE_NY).toDate()
                                     })

    return adminDisabledFilteredDates
  }

  // Get cap for latest date a user can place an order
  const getMaxDate = () => {
    return moment().add(ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER_NY, 'days')
  }

  // Format and return all order data
  const getOrder = (serverFriendly = false) => {
    var requestedDate = getDateStr(order.requestedDate)
    if (serverFriendly) {
      // Client needs date formatted MM-DD-YYYY, server needs date formatted YYYY-MM-DD
      requestedDate = getDateStrYYYYMMDD(order.requestedDate)
    }

    return {
      email: user.email,
      excludedShows: Object.keys(userEliminatedShows),
      location: order.location,
      retailer: order.retailer,
      numberOfTickets: order.numberOfTickets,
      requestedShowDate: requestedDate,
      requestedShowTime: order.requestedTime,
      rouletteType: order.rouletteType,
      isBundle: order.isBundle,
      isNewUser: user.isNewUser,
      isRequestingAdditionalExclusions: !!order.isRequestingAdditionalExclusions,
      isRequestingFamilyFriendlyExclusions: !!order.isRequestingFamilyFriendlyExclusions,
      isRequestingChildFriendly: order.eventPreference === 'Family-Friendly',
      isRequestingPremium: order.eventPreference === 'Premium',
      isRequestingRestaurantReservation: !!order.isRequestingRestaurantReservation,
      isRequestingTicketFlex: order.isRequestingTicketFlex,
      eventPreference: order.eventPreference,
      isOptingIntoMailingList: false,
      showPreference: 'Both',
      clientVersion: '1.0.0'
    }
  }

  // Retrieve and format a users previously attended shows
  const getPreviouslyAttendedShows = () => {
    if (!isUserAuthenticated || !user.orders || !(user.orders instanceof Array)) { return [] }

    var previouslySeenShows = user.orders.filter(order => {
      return order.isValidOrder && order.requestedDate < moment().format('YYYY-MM-DD') &&
             order.location.name === 'Broadway' && order.retailer.name === 'Goldstar'
    })
    .map(order => order.ticketDetails.show)
    .filter(show => show && show.id)

    var previouslySeenShowIds = {}
    previouslySeenShows.forEach(show => { previouslySeenShowIds[show.id] = true })

    return previouslySeenShowIds
  }


  const handleConfirmPayment = async (paymentResult, orderId) => {
    return new Promise(async (resolve, reject) => {
      try {
        var charge = getCharge(user, order)

        var response = await BRClient.confirmStripePayment(paymentResult, charge, 'usd', orderId)
        var responseJson = await response.json()

        resolve(responseJson)

      } catch (e) {
        console.log(e)
        handlePaymentFailure({
          message: `We're sorry! We weren't able to place your order just now. Please try again in a little while.`
        })
      }
    })
  }

  const handlePaymentFailure = ({ message }) => {
    setAlertStr(message)
    setIsResolvingPurchase(false)
    setIsAlertPopupVisible(true)

    console.log(message)

    sendGAEvent(GA_EVENT.EVENT, GA_ACTION.EVENT.PAYMENT_FAILED)
  }

  const handlePaymentSuccess = (orderId) => {
    var charge = getCharge(user, order)
    var orderDetails = getOrder()
    var pricing = getPricingForOrder(orderDetails, user, serverPricing)

    var numberOfTickets = order.numberOfTickets
    var requestedShowDate = order.requestedShowDate
    var totalCost = charge.amount / USD_DOLLARS_TO_CENTS
    var itemName = 'Roulette NY tickets'

    sendPurchaseGAEvent(orderId, totalCost, purchaseCode, itemName)
    props.history.push(`/confirmation/${orderId}?location=${order.location}`)
  }

  const getCharge = (user, order) => {
    var userEmail = user.email
    var orderDetails = getOrder()
    var pricingInfo = getPricingForOrder(orderDetails, user, serverPricing)

    return {
      description: userEmail,
      zipCode: true,
      amount: pricingInfo.totalCost * USD_DOLLARS_TO_CENTS
    }
  }

  const handleCreateOrder = async (paymentResult, paymentSource, paymentMethod) => {
    return new Promise(async (resolve, reject) => {
      setIsResolvingPurchase(true)
      var userOrderCount = user.orders && user.orders.length > 0
                           ? user.orders.filter(order => order.isValidOrder).length
                           : 0
      user.orders = userOrderCount
      var charge = getCharge(user, order)

      // Create an order object consumable by the BE
      var formattedOrder = getOrder(true)

      if (paymentMethod && paymentMethod.billing_details) {
        user.zipCode = paymentMethod.billing_details.address.postal_code
      }

      try {
        var response = await BRClient.createOrder(charge, user, formattedOrder, purchaseCode, giftCardCode, paymentSource, paymentResult)
        var responseJson = await response.json()

        resolve(responseJson)

      } catch (e) {
        console.log(e)
        handlePaymentFailure({
          message: `We're sorry! We weren't able to place your order just now. Please try again in a little while.`
        })
      }
    })
  }

  const handleUpdateOrder = (key, value) => {
    var newOrderState = {...order}

    newOrderState[key] = value
    setOrder(newOrderState)
  }

  const handleChangeEventPreference = (togglePreference) => {
    handleUpdateOrder('eventPreference', togglePreference)

    setUserEliminatedShows([])
    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.SHOW_PREFERENCE)
  }

  const handleAcceptTicketflex = () => {
    setIsTicketFlexPopupVisible(false)
    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', true)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.TICKETFLEX_YES_POPUP)
  }

  const handleRejectTicketflex = () => {
    setIsTicketFlexPopupVisible(false)
    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', false)
  }

  const handleToggleParkingReservation = () => {
    var newParkingReservation = !order.isRequestingParkingReservation

    setPricingDirty(true)
    handleUpdateOrder('isRequestingParkingReservation', newParkingReservation)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.PARKING_RESERVATION)
  }

  const handleToggleRestaurantReservation = () => {
    var newRestaurantReservation = !order.isRequestingRestaurantReservation

    setPricingDirty(true)
    handleUpdateOrder('isRequestingRestaurantReservation', newRestaurantReservation)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.RESTAURANT_RESERVATION)
  }

  const handleToggleTicketFlex = () => {
    var newTicketFlex = !order.isRequestingTicketFlex

    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', newTicketFlex)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.TICKETFLEX)
  }

  const handleToggleTicketFlexDialog = () => {
    setIsTicketFlexPopupVisible(false)

    sendGAEvent(GA_EVENT.VIEW, GA_ACTION.VIEW.TICKETFLEX_POPUP)
  }

  const loadAdminDisabledDates = async () => {
    try {
      if (!props.adminDisabledDatesQuery.loading && !props.adminDisabledDatesQuery.error) {
        var adminDisabledDatesQuery = props.adminDisabledDatesQuery
        setAdminDisabledDates(adminDisabledDatesQuery.disabledDatesList.items)
      }

    } catch (err) {
      console.log(`Unable to load excluded dates: ${err.message}`)
      console.log(err)
    }
  }

  const loadConfig = async () => {
    try {
      var configQuery = await props.client.query({
        query: CONFIG_JSON_QUERY,
        variables: { key: 'frequencyCap' }
      })

      setFrequencyCap(configQuery.data.configJson.value)

    } catch (err) {
      console.log(`Unable to load config: ${err.message}`)
    }
  }

  const loadUser = async () => {
    try {
      var userQuery = await BRClient.getUserDetails(email)
      var userDetails = await userQuery.json()

      if (!userDetails) {
        setHasLoadedUser(true)
        return
      }

      var email = userDetails.email
      var firstName = userDetails.firstName
      var isNewUser = false
      var lastName = userDetails.lastName
      var mobilePhone = userDetails.mobilePhone

      var orders = userDetails.orders.items && userDetails.orders.items.map(order => {
        if (order.isValidOrder && order.location.name === 'Broadway' && order.retailer.name === 'Goldstar') {
          return order
        }
      })

      var capDate = moment().subtract(3, 'year').format("YYYY-MM-DD")
      var previousSpinsCount = orders ? orders.filter(order => ( order.orderPlacedAt > capDate )).length : 0

      var previouslySeenShows = orders ? orders.filter(order => (
        order.orderPlacedAt < TODAY && order.ticketDetails && order.ticketDetails.show
      )) : []

      var purchaseCredits = userDetails.purchaseCredits ? userDetails.purchaseCredits.items.filter(credit => (
        !credit.isExpired && credit.currency === 'USD' && credit.remainingAmount > 0
      )) : []

      var userEliminatedShows = {}

      setUser({
        id: userDetails.id,
        email,
        firstName,
        isNewUser,
        lastName,
        mobilePhone,
        orders,
        previousSpinsCount,
        purchaseCredits,
        userEliminatedShows
      })

      setHasLoadedUser(true)
    } catch (err) {
      console.log(`Unable to get data for user: ${err.message}`)
      setHasLoadedUser(true)
    }
  }

  const resetPurchaseCode = () => {
    setPurchaseCode('')
    setPricingDirty(true)
  }

  useEffect(() => {
    loadConfig()
    autoAdvanceNextAvailableShowDate()
  }, [])

  useEffect(() => {
    loadAdminDisabledDates()
  }, [props.adminDisabledDatesQuery])

  useEffect(async () => {
    try {
      var response = await BRClient.getUserDetailsFromGoldstar()
      var responseJson = await response.json()
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    ensureValidDateTimeSelected()
  }, [disabledDates, order, user])

  useEffect(() => {
    // if show preference is changed, clear restaurant reservation add-on and update pricing
    handleUpdateOrder('isRequestingRestaurantReservation', false)
    setPricingDirty(true)
  }, [order.eventPreference])

  useEffect(() => {
    if (!hasLoadedUser) {
      loadUser()
    }
  }, [hasLoadedUser, user])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  var maxDate = getMaxDate()
  var requestedDateString = moment(order.requestedDate).format('YYYY-MM-DD')
  var dayOfWeekRequested = moment(order.requestedDate, 'MM-DD-YYYY').format('dddd')

  var disabledDates = getAllDisabledDates()

  // Load disabled dates for selected date
  var disabledOptionsForDate = getDisabledOptionsForDate(requestedDateString)
  // Narrow down disabled dates to selected time
  var disabledOptionsForTime = disabledOptionsForDate[order.requestedTime.toLowerCase()]

  // Check if any times for selected date are entirely disabled for all spin types
  var selectedDateEveningDisabledDates = disabledOptionsForDate.evening.filter(disabledDate => (
    disabledDate.isForAllShows
  ))

  var selectedDateMatineeDisabledDates = disabledOptionsForDate.matinee.filter(disabledDate => (
    disabledDate.isForAllShows
  ))

  return (
    <div className="spin static-page">
      <Helmet>
        <title>Goldstar Roulette</title>
        <meta name="description" content="Play Goldstar Roulette to attend an LA show on a budget" />
      </Helmet>

      <Stack className="page-width-stack">
        <div className="header-section">
          {windowWidth > 950 &&
            <img src={desktopHeader} className="header-image" width="1800px" />
          }

          {windowWidth <= 950 && windowWidth > 420 &&
            <img src={tabletHeader} className="header-image" width="1000px" />
          }

          {windowWidth <= 420 &&
            <img src={mobileHeader} className="header-image" width="500px" />
          }
        </div>

        <hr className="divider" />

        <Stack className="logos">
          <div className="logos-text">As seen in:</div>

          <div className="logos-flex">
            <a target="_blank" rel="noopener noreferrer" href="https://www.forbes.com/sites/jerylbrunner/2019/09/03/how-one-entrepreneur-doubles-her-revenues-each-year-and-continues-to-stay-innovative/?sh=46cf614242f3">
              <img src={forbes}className="company-logo" alt="Forbes" width="300px" height="83" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.hollywoodreporter.com/news/broadway-roulette-lets-theatergoers-gamble-tickets-1129381">
              <img src={hollywoodReporter} className="company-logo hollywood" alt="Hollywood Reporter" width="300px" height="78" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.purewow.com/entertainment/broadway-roulette-nyc">
              <img src={pureWow} className="company-logo" alt="Pure Wow" width="300px" height="102" />
            </a>
          </div>
        </Stack>

        <DateAndTicketSelection disabledDates={disabledDates}
                                getDefaultEventTime={getDefaultEventTime}
                                handleUpdateOrder={handleUpdateOrder}
                                location="Broadway"
                                maxDate={maxDate}
                                minDate={MIN_DATE}
                                order={order}
                                resetPurchaseCode={resetPurchaseCode}
                                setOrder={setOrder}
                                setPricingDirty={setPricingDirty}
                                user={user}
                                windowWidth={windowWidth} />
      </Stack>

      <ShowElimination  disabledOptionsForDate={disabledOptionsForDate}
                        getPreviouslyAttendedShows={getPreviouslyAttendedShows}
                        handleUpdateOrder={handleUpdateOrder}
                        order={order}
                        setOrder={setOrder}
                        setPricingDirty={setPricingDirty}
                        setUserEliminatedShows={setUserEliminatedShows}
                        userEliminatedShows={userEliminatedShows}
                        windowWidth={windowWidth} />

      <Stack className="page-width-stack">
        <div className="add-ons-section">
          <Stack className="add-ons-stack">

            <AddOn booleanCheck={order.isRequestingTicketFlex}
                   cost={`${PER_TICKET_TICKET_FLEX_FEE}/Ticket`}
                   description={'Things happen! Ticket Protection gives you the flexibility to change your date up until 72 hours before your scheduled performance.'}
                   handleToggle={handleToggleTicketFlex}
                   title={'Ticket Protection'}
                   windowWidth={windowWidth} />

            <AddOn booleanCheck={order.isRequestingRestaurantReservation}
                   cost={`${PER_ORDER_RESTAURANT_FEE}`}
                   description={'Make the entire outing a surprise! We’ll make a reservation for you based on your show location and number of people.'}
                   handleToggle={handleToggleRestaurantReservation}
                   title={'Restaurant Reservation'}
                   windowWidth={windowWidth} />
          </Stack>
        </div>
      </Stack>

      <Stack className="page-width-stack">
        <Stack className="how-step-stack">
          <Stack direction="row" alignItems="center">
            <img src={box} width="100px" height="auto" className="how-image" />
          </Stack>

          <Stack direction="column">
            <div className="how-title">Checkout to be surprised with an amazing show</div>
            <div className="how-desc">The day of your selected date, find out which show you won!</div>
          </Stack>
        </Stack>
      </Stack>

      <Checkout alertStr={alertStr}
                getAllDisabledDates={getAllDisabledDates}
                getCharge={getCharge}
                getOrder={getOrder}
                getPricingForOrder={getPricingForOrder}
                giftCardCode={giftCardCode}
                handleConfirmPayment={handleConfirmPayment}
                handleCreateOrder={handleCreateOrder}
                handlePaymentFailure={handlePaymentFailure}
                handlePaymentSuccess={handlePaymentSuccess}
                handleUpdateOrder={handleUpdateOrder}
                isAlertPopupVisible={isAlertPopupVisible}
                isResolvingPurchase={isResolvingPurchase}
                eventLocation="Broadway"
                order={order}
                pricingDirty={pricingDirty}
                purchaseCode={purchaseCode}
                resetPurchaseCode={resetPurchaseCode}
                setAlertStr={setAlertStr}
                setGiftCardCode={setGiftCardCode}
                setIsAlertPopupVisible={setIsAlertPopupVisible}
                setPricingDirty={setPricingDirty}
                setPurchaseCode={setPurchaseCode}
                setServerPricing={setServerPricing}
                setUser={setUser}
                serverPricing={serverPricing}
                user={user}
                userEliminations={userEliminatedShows} />

      <div className="footer">
        Use of this site is governed by our <a href="https://www.goldstar.com/company/tos">Terms of Service</a>&nbsp;
        and <a href="https://www.goldstar.com/company/privacy">Privacy Policy</a> ©2022 Goldstar Events, Inc.
      </div>

      <Dialog className="popup-dialog"
              onClose={() => { setIsCategoryDisabledPopupVisible(false) }}
              open={isCategoryDisabledPopupVisible} >
        <div className="popup-content">
          <h2>
            Sorry, this spin category is not available for your selected date and time.
          </h2>
          <span className="dialog-close header-description"
                onClick={() => { setIsCategoryDisabledPopupVisible(false) }}>
            <CloseIcon />
          </span>
        </div>
      </Dialog>
    </div>
  )
}

const DISABLED_SHOW_DATES_LIST_QUERY = gql`
  query DisabledDatesListQuery($today: Date!) {
    disabledDatesList(
      sort: { date: DESC },
      filter: {
        date: { gte: $today },
        isActive: { equals: true },
        location: { name: { equals: "Broadway" }}
      }
    ){
      items {
        id
        date
        isActive
        isForAllShows
        isForMatinee
        isForMatineeFamilyRoulette
        isForMatineePremiumRoulette
        isForMatineeRegularRoulette
        isForEvening
        isForFamilyRoulette
        isForPremiumRoulette
        isForRegularRoulette
      }
    }
  }
`

const CONFIG_JSON_QUERY = gql`
  query ConfigQuery($key: String!) {
    configJson(key: $key) {
      id
      key
      value
    }
  }
`

export default withRouter(withApollo(graphql(DISABLED_SHOW_DATES_LIST_QUERY, {
  name: 'adminDisabledDatesQuery',
  options: (ownProps) => {
    var today = moment().format('YYYY-MM-DD')

    return {
      notifyOnNetworkStatusChange: true,
      variables: {
        today
      }
    }
  }
})(SpinPageNY)))