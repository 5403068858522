import 'whatwg-fetch'

import { BR_API_KEY, BR_API_URL } from './constants.js'


const EVENT_ORDER_SERVICE_PREFIX = '/event-order'
const ORDER_SERVICE_PREFIX = '/order'
const USER_SERVICE_PREFIX = '/user'

const EVENT_ORDER_METHODS = {
  EVENT_ORDER: '/event-order',
  EVENT_ORDER_PRICING: '/eventOrderPricing',
  USER_DETAILS: '/getUserDetails',
  USER_DETAILS_FROM_ORDER: '/getUserDetailsFromOrder',
}

const ORDER_METHODS = {
  CONFIRM_PAYMENT: '/confirmStripePayment',
  ORDER: '/order',
  ORDER_PRICING: '/orderPricing',
  USER_EXISTS_EMAIL: '/userExistsEmail',
  USER_EXISTS_PHONE: '/userExistsPhone',
  USER_DETAILS_FROM_ORDER: '/getUserName'
}

const USER_METHODS = {
  CONFIRM_ORDER: '/confirmOrder',
  CREATE_ORDER_SURVEY: '/createOrderSurvey',
  UPDATE_ORDER_SURVEY: '/updateOrderSurvey',
}

const confirmOrder = (orderStatusId) => {
  const REQUEST_URL = BR_API_URL + USER_SERVICE_PREFIX + USER_METHODS.CONFIRM_ORDER

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({ orderStatusId }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const confirmStripePayment = (paymentResult, charge, currency, orderId) => {
  const REQUEST_URL = BR_API_URL + ORDER_SERVICE_PREFIX + ORDER_METHODS.CONFIRM_PAYMENT

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      paymentResult,
      charge,
      currency,
      orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const createEventOrder = (charge, user, order, discountCode, giftCardCode, paymentSource, paymentResult) => {
  const REQUEST_URL = BR_API_URL + EVENT_ORDER_SERVICE_PREFIX + EVENT_ORDER_METHODS.EVENT_ORDER

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      charge,
      user,
      order,
      discountCode,
      giftCardCode,
      paymentSource,
      paymentResult
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const createOrder = (charge, user, order, discountCode, giftCardCode, paymentSource, paymentResult) => {
  const REQUEST_URL = BR_API_URL + ORDER_SERVICE_PREFIX + ORDER_METHODS.ORDER

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      charge,
      user,
      order,
      discountCode,
      giftCardCode,
      paymentSource,
      paymentResult
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const createOrderSurvey = (orderId, rating) => {
  const REQUEST_URL = BR_API_URL + USER_SERVICE_PREFIX + USER_METHODS.CREATE_ORDER_SURVEY

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({ orderId, rating }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getPriceForEventOrder = (order, user, discountCode, giftCardCode, location) => {
  const REQUEST_URL = BR_API_URL + EVENT_ORDER_SERVICE_PREFIX + EVENT_ORDER_METHODS.EVENT_ORDER_PRICING

  const queryParams = `?order=${encodeURIComponent(JSON.stringify(order))}` +
                      `&user=${encodeURIComponent(JSON.stringify(user))}` +
                      `&discountCode=${discountCode}` +
                      `&giftCardCode=${giftCardCode}` +
                      `&location=${location}`

  const requestUrlWithParams = `${REQUEST_URL}${queryParams}`

  return fetch(requestUrlWithParams, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getPriceForOrder = (order, user, discountCode, giftCardCode, location) => {
  const REQUEST_URL = BR_API_URL + ORDER_SERVICE_PREFIX + ORDER_METHODS.ORDER_PRICING

  const queryParams = `?order=${encodeURIComponent(JSON.stringify(order))}` +
                      `&user=${encodeURIComponent(JSON.stringify(user))}` +
                      `&discountCode=${discountCode}` +
                      `&giftCardCode=${giftCardCode}` +
                      `&location=${location}`

  const requestUrlWithParams = `${REQUEST_URL}${queryParams}`

  return fetch(requestUrlWithParams, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getUserDetails = () => {
  const REQUEST_URL = BR_API_URL + EVENT_ORDER_SERVICE_PREFIX + EVENT_ORDER_METHODS.USER_DETAILS

  const requestUrlWithParams = `${REQUEST_URL}`

  return fetch(requestUrlWithParams, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'include'
  })
}

const getUserDetailsFromGoldstar = () => {
  const REQUEST_URL = 'https://www.goldstar.com/graphql'

  return fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'omit'
  })
}

const getUserName = (orderId) => {
  const REQUEST_URL = BR_API_URL + ORDER_SERVICE_PREFIX + ORDER_METHODS.USER_DETAILS_FROM_ORDER

  const queryParams = `?&orderId=${orderId}`
  const requestUrlWithParams = `${REQUEST_URL}${queryParams}`

  return fetch(requestUrlWithParams, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getUserDetailsFromOrder = (orderId) => {
  const REQUEST_URL = BR_API_URL + EVENT_ORDER_SERVICE_PREFIX + EVENT_ORDER_METHODS.USER_DETAILS_FROM_ORDER

  const queryParams = `?&orderId=${orderId}`
  const requestUrlWithParams = `${REQUEST_URL}${queryParams}`

  return fetch(requestUrlWithParams, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const updateOrderSurvey = (orderSurveyId, rating, orderSurveyParams) => {
  const REQUEST_URL = BR_API_URL + USER_SERVICE_PREFIX + USER_METHODS.UPDATE_ORDER_SURVEY

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({ orderSurveyId, rating, orderSurveyParams }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const BRClient = {
  confirmOrder,
  confirmStripePayment,
  createEventOrder,
  createOrder,
  createOrderSurvey,
  getPriceForEventOrder,
  getPriceForOrder,
  getUserDetails,
  getUserDetailsFromGoldstar,
  getUserDetailsFromOrder,
  getUserName,
  updateOrderSurvey,
}

export default BRClient
