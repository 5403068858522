import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment-timezone'

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function addDaysToDateStrMMDDYYYYUsingMoment(dateStr, numberOfDays) {
  var date = moment(dateStr, 'MM-DD-YYYY')
  var newDate = date.add(numberOfDays, 'days')

  return newDate.format('YYYY-MM-DD')
}

export function addDaysToDateStrYYYYMMDDUsingMoment(dateStr, numberOfDays) {
  var date = moment(dateStr, 'YYYY-MM-DD')
  var newDate = date.add(numberOfDays, 'days')

  return newDate.format('YYYY-MM-DD')
}

// TODO: Deprecate and move everything to YYYYMMDD
export function getDateStr(date) {
  return moment(date).format('MM-DD-YYYY')
}

export function getDateStrYYYYMMDD(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function getInternationalPhoneNumber(phoneNumber) {
  var phoneNumberPlain = new PhoneNumber(phoneNumber)
  var phoneNumberAssumingUS = new PhoneNumber('+1' + phoneNumber)
  var phoneNumberAssumingInternational = new PhoneNumber('+' + phoneNumber)
  var phoneNumberAssumingInternational2 = new PhoneNumber('+' + phoneNumber.replace(/^0*/g, ''))

  var phoneNumberObj = phoneNumberPlain
  if (!phoneNumberObj.a.valid) { phoneNumberObj = phoneNumberAssumingUS }
  if (!phoneNumberObj.a.valid) { phoneNumberObj = phoneNumberAssumingInternational }
  if (!phoneNumberObj.a.valid) { phoneNumberObj = phoneNumberAssumingInternational2 }

  var internationalNumber
  if (phoneNumberObj.a.valid) {
    internationalNumber = phoneNumberObj.a.number.international
  }

  return internationalNumber
}
